import {useEffect, useState, useContext} from 'react'
import HitBitApi from '../lib/hitbitApi'
import MainLayout from '../layouts/mainLayout';
import ReportedApplication from '../components/codeRequest/reportedApplication';
function Application(){
    const [list,setList] = useState([]);
    const hitbitApi = useContext(HitBitApi)

    const getApplication = function(){
        hitbitApi.userApplication().then(res=>{            
            setList(res.applications);
        });
    }

    useEffect(()=>{
        getApplication();
    },[]);

    return (
        <>
        <MainLayout>                
            <div className="holdcoin_box">
                <div className="c_tit">내거래목록 </div>            
            </div>

            <div className="basic_box deal">
                <div className="titwrap d-flex justify-content-between">
                    <div>내거래목록</div>
                                    
                </div>
                <ul className="coin_list">
                    {list.map((value,index)=>{
                        return (
                            <ReportedApplication key={"aid_"+value.id} application={value}/>
                        )
                    })}
                </ul>
            </div>              
            </MainLayout>
        </>
    )
}

export default Application