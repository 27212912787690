import React,{useState,useContext} from 'react'
import {Link,useHistory} from 'react-router-dom'
import HitBitApi from '../lib/hitbitApi'
import AlertMessage from '../lib/alertMessage';

function Join(){
    
    const [userId,setUserId] = useState('');
    const [password,setPassword] = useState('');
    const [passwordConfirmation,setPasswordConfirmation] = useState('');
    const [mob,setmob] = useState('');
    const [code,setCode] = useState('');
    const hitbitApi = useContext(HitBitApi);
    const history = useHistory();

    const inputUserId = function (e){
        setUserId(e.target.value);
    }
 
    const inputPassword = function (e){
        setPassword(e.target.value);
    }

    const inputPasswordConfirmation = function (e){
        setPasswordConfirmation(e.target.value);
    }

    const inputMob = function (e){
        setmob(e.target.value);
    }

    const sendCode = function(){
        hitbitApi.sendCode({mob}).then(res=>{
            if(res.statusCode === 200){
                AlertMessage('입력하신 연락처로 인증문자를 전송하였습니다.','success');
            }else{
                AlertMessage(res.message);
            }
        }).catch(e=>{
            if(e.response){
                if(e.response.status === 429){
                    AlertMessage("짧은 시간에 많이 시도 하셨습니다. 1분후에 다시 시도해 주세요.");
                }else{
                    AlertMessage(e.response.message);
                }
            }
        })
    }

    const join = function(){
        hitbitApi.join({user_id:userId,
            password,
            passwordConfirmation,
            mob,code}).then(res=>{
                if(res.statusCode === 200){
                    AlertMessage("가입하였습니다. 로그인 페이지로 이동합니다.",'success',()=>{
                        history.push('/');
                    });
                  }else{
                    AlertMessage(res.message);
                  }
            }).catch(e=>{
                if(e.response){
                    if(e.response.status === 429){
                        AlertMessage("짧은 시간에 많이 시도 하셨습니다. 1분후에 다시 시도해 주세요.");
                    }else{
                        AlertMessage(e.response.message);
                    }
                }
            })
    }

    return (
        
            <div className="container">
            <section id="login" className="login">
                <div className="container">
                <div className="row">
                    <div className="col-md-5 mx-auto">
                    <div id="signup">
                        <div className="myform form ">
                        <div className="mb-3">
                            <div className="section-title">
                            <h2>회원가입</h2>
                            <h3>No.1 가상자산 플랫폼, <span>Hitbit</span></h3>
                            </div>
                        </div>
                        <form action="#" name="registration" noValidate="novalidate">
                            <div className="form-group">
                            <label htmlFor="userid">아이디</label>
                            <input type="email" className="form-control" aria-describedby="userid" placeholder="아이디를 입력해 주세요" onChange={inputUserId}/>
                            </div>
                            <div className="form-group">
                            <label htmlFor="password">패스워드</label>
                            <input type="password" className="form-control" aria-describedby="password" placeholder="비밀번호를 입력해주세요" onChange={inputPassword}/>
                            </div>
                            <div className="form-group">
                            <label htmlFor="passwordConfirmation">패스워드 확인</label>
                            <input type="password" className="form-control" aria-describedby="passwordConfirmation" placeholder="비밀번호를 다시한번 입력해주세요" onChange={inputPasswordConfirmation}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="contract">연락처</label>
                                <div className="input-group">
                                    <input type="name" className="form-control" aria-describedby="contract" placeholder="연락처를 입력해 주세요" onChange={inputMob}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-primary btn-outline" type="button" onClick={sendCode}>인증번호전송</button>
                                    </div>
                                </div>                                
                            </div>
                            <div className="form-group">
                                <label htmlFor="code">인증번호</label>
                                <input type="text" className="form-control" aria-describedby="code" placeholder="인증번호를 입력해 주세요" onChange={(e)=>{setCode(e.target.value)}}/>
                            </div>
                            <div className="col-md-12 text-center mb-3">
                            <button type="button" onClick={join} className=" btn btn-block mybtn btn-primary tx-tfm">회원가입</button>
                            </div>
                            <div className="col-md-12 mt-5">
                            <div className="form-group">
                                <p className="text-center">회원님의 계정이 있으신가요? <Link to="/" id="login">로그인</Link></p>
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            </div>
        

    )

}

export default Join