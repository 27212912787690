import { useState,useContext,useEffect,useRef } from "react";
import MainLayout from "../layouts/mainLayout"
import HitBitApi from '../lib/hitbitApi'
import {tradingTableAmount} from '../lib/misc'
import {debounce} from 'lodash'

function SellTable(){
    const hitbitApi = useContext(HitBitApi)
    const [list,setList] = useState([]);    
    const [isTableLoading,setTableLoading] = useState(false);

    const handleRefreshButton = useRef(debounce(()=>{
        hitbitApi.sellTradingTable().then(res=>{
            if(res.statusCode === 200){                
                setList(res.list);
            }
        }).finally(()=>{
            setTableLoading(false);
        });
    },1000)).current

    const handleRefreshButtonClick = function(){
        setTableLoading(true);
        handleRefreshButton();
    }
    
    
    const loadList = function(){
        setTableLoading(true)
        hitbitApi.sellTradingTable().then(res=>{
            if(res.statusCode === 200){                
                setList(res.list);
            }
        }).finally(()=>{
            setTableLoading(false);
        });
    }

    useEffect(()=>{
        loadList();
    },[])


    return (
        <>
        <MainLayout>

      
        <div className="holdcoin_box">
            <div className="c_tit">판매목록 <a className="i_refresh01" onClick={()=>{
                handleRefreshButtonClick()
            }}>새로고침</a></div>            
        </div>

        <div className="basic_box deal">
            <div className="titwrap d-flex justify-content-between">
                <div>판매거래</div>
                                
            </div>
            <ul className="coin_list">
            {!isTableLoading && list.map((value,index)=>{
                return (
                    <li key={`buy_${value.id}`}>
                        <p className="type">{value.token_name}({value.token_id})<span>{tradingTableAmount(value.amount)}</span><span className="date">{value.user_id}</span></p>
                        <p className="num">{tradingTableAmount(value.amount)}<span>{value.title}</span><span>{value.minPrice} - {value.maxPrice} 원</span></p>
                    </li>                 
                )
            })}
            {isTableLoading && [1,2,3].map((value,index)=>{
                return (
                    <li className="datagrid__row" key={"skeleton"+index}>                        
                        <p><span><div className="datagrid__loader" style={{width:'70%'}}></div></span></p>
                        <p><span><div className="datagrid__loader" style={{width:'70%'}}></div></span></p>                        
                        <p><span><div className="datagrid__loader" style={{width:'70%'}}></div></span></p>                        
                    </li>
                )
            })
            }
            </ul>
        </div>              
        </MainLayout>
        </>
    )
}



export default SellTable