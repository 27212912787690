
import { useContext,useState } from "react"
import ModalBackDrop from "../../components/modalBackdrop"
import HitBitApi from '../../lib/hitbitApi'
import AlertMessage from "../../lib/alertMessage"

function SellModal(props){
    const hitbitApi = useContext(HitBitApi)    
    

    const [token,setToken] = useState('');
    const [target,setTarget] = useState('');
    const [amount,setAmount] = useState(0);
    

    const submit = function(){
      
      if(!token){
        AlertMessage("토큰을 선택해 주세요");
        return ;
      }
      props.setLoading(true);
      hitbitApi.transfer({token_id:token,target,amount}).then(data=>{        
        if(data.statusCode === 404){
          AlertMessage(data.message);
        }else{
          AlertMessage(data.message,'success',(value)=>{
            props.setSendModalShow(false)
          });
        }
      }).finally(()=>{
        props.setLoading(false);
      })
      
    }

    
    return (
        props.isShow &&
        <>
        <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" style={{'display':'block'}}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="pop_head"> 

                <div className="titlewrap d-flex justify-content-between">
                  <p className="pop_title">전송하기</p>
                  <button type="button" className="btn btn-default text-white btn-sm" onClick={()=>{props.setSendModalShow(false)}}>닫기</button>
                </div>

              </div>
              
              <div className="pop_content">
                <table className="list_tb03">
                  <colgroup>
                  <col style={{width:'30%'}} />
                  <col style={{width:"70%"}} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">거래 코인</th>
                      <td>
                        <select className="form-control" onChange={(e)=>{setToken(e.target.value)}}>
                        <option>토큰을 선택해 주세요.</option>   
                        {props.tokens.map((value,index)=>{
                        return (
                            <option key={value.contract} value={value.token_id}>{value.token_name}</option>   
                          )
                      })}

                        </select>                                              
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">아이디</th>
                      <td><input type="text" className="form-control" placeholder="아이디를 입력해 주세요."  onChange={(e)=>{setTarget(e.target.value)}}/></td>
                    </tr>
                    <tr>
                      <th scope="row"><label htmlFor="sum">전송수량</label></th>
                      <td><input type="number" step='1' className="form-control" placeholder="전송할 수량을 입력해 주세요" onChange={(e)=>{setAmount(e.target.value)}}/></td>
                    </tr>                    
                  </tbody>
                </table>
                
                <div className="float-right mt-3">
                  <button type="button" className="btn btn-outline-primary btn-sm" onClick={submit}>전송</button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <ModalBackDrop/>        
        </>
    )

}


export default SellModal
