
import { useContext,useState } from "react"
import ModalBackDrop from "../../components/modalBackdrop"
import HitBitApi from '../../lib/hitbitApi'
import AlertMessage from "../../lib/alertMessage"

function ChangePasswordModal(props){
    const hitbitApi = useContext(HitBitApi)    

    const [originalPassword,setOriginalPassword] = useState('');
    const [password,setPassword] = useState('');
    const [passwordConfirmation,setPasswordConfirmation] = useState('');    

    const submit = function(){
      props.setLoading(true);

      hitbitApi.changePassword({originalPassword,      
        password,
        passwordConfirmation}).then(res=>{          
          
          if(res.statusCode === 200){
            props.setChangePasswordModal(false);
            AlertMessage(res.message,'success');
          }else{
            AlertMessage(res.message);
          }
      }).catch(e=>{
        if(e.response){
          if(e.response.status === 429){
              AlertMessage("짧은 시간에 많이 시도 하셨습니다. 1분후에 다시 시도해 주세요.");
          }else{
              AlertMessage(e.response.message);
          }
       }
      }).finally(()=>{
        props.setLoading(false);
      });
    }

    
    return (
        props.isShow &&
        <>
        <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" style={{'display':'block'}}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="pop_head"> 

                <div className="titlewrap d-flex justify-content-between">
                  <p className="pop_title">패스워드 변경</p>
                  <button type="button" className="btn btn-default text-white btn-sm" onClick={()=>{props.setChangePasswordModal(false)}}>닫기</button>
                </div>

              </div>
              
              <div className="pop_content">
                <table className="list_tb03">
                  <colgroup>
                  <col style={{width:'30%'}} />
                  <col style={{width:"70%"}} />
                  </colgroup>
                  <tbody>                    
                    <tr>
                      <th scope="row">패스워드</th>
                      <td><input type="password" className="form-control" onChange={(e)=>{setOriginalPassword(e.target.value)}}/></td>
                    </tr>
                    <tr>
                      <th scope="row"><label htmlFor="sum">변경할 패스워드</label></th>
                      <td><input type="password" className="form-control" onChange={(e)=>{setPassword(e.target.value)}}/></td>
                    </tr>
                    <tr>
                      <th scope="row"><label htmlFor="sum">변경할 패스워드 확인</label></th>
                      <td><input type="password" className="form-control" onChange={(e)=>{setPasswordConfirmation(e.target.value)}}/></td>
                    </tr>
                  </tbody>
                </table>
                
                <div className="float-right mt-3">
                  <button type="button" className="btn btn-outline-primary btn-sm" onClick={submit}>수정</button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <ModalBackDrop/>        
        </>
    )

}

export default ChangePasswordModal