import React,{useContext, useEffect} from 'react'
import HitBitApi from '../lib/hitbitApi'
import {Link,useHistory} from 'react-router-dom'
import AlertMessage from '../lib/alertMessage'
import queryString from 'query-string'


function Loading(props){
    
    const hitbitApi = useContext(HitBitApi)
    const history = useHistory();
    const {amount,callback,content} = queryString.parse(props.location.search);

    
    useEffect(()=>{       
        const hashString = props.match.params.hashString;
        const code = props.match.params.code;
        
        
        
        hitbitApi.requestFromHash({hashString,code,amount,callback,content}).then((res)=>{
            console.log(amount,callback,content);
            if(res.success){
                if(code){
                    history.push('/request/code/'+code);
                }else{
                    history.push('/main');
                }
            }else{
                AlertMessage(res.message,"error");
            }
        });
    },[])

    return (
        <section>
            <div className="container">
            <section id="login" className="login">
                <div className="container">
                <div className="row">
                    <div className="col-md-5 mx-auto">                    
                    <div id="login">
                        <div className="myform form ">
                        <div className="mb-3">
                            <div className="section-title">
                                <h2>Loading</h2>
                                <h3>페이지 이동중입니다.</h3>
                                <h3>잠시만 기다려 주세요.</h3>
                                <h3><span>Hitbit</span></h3>
                            </div>
                            <div className="loader"></div>
                        </div>                            
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
            </section>

            </div>
        </section>

    )
}



export default Loading