import HitBitApi from '../lib/hitbitApi'
import MainLayout from '../layouts/mainLayout'
import {useHistory} from 'react-router-dom'
import { useEffect, useState,useContext } from 'react'
import {shortDateTime} from '../lib/time'
import AlertMessage from "../lib/alertMessage"
import {isLoginContext} from '../components/isLoginContext'
import Pagination from '../components/pagenation'
import { PreloaderContext } from '../components/preloaderContext'
import ChangePasswordModal from './modal/ChangePasswordModal'
import {numberFormat} from '../lib/misc'

function MyInfo(){
    const hitbitApi = useContext(HitBitApi)    
    const {setLoading} = useContext(PreloaderContext);
    const [info,setInfo] = useState({user_id:'',address:'',createdAt:'',layoutIndex:0});
    const [list,setList] = useState([]);
    const {setIsLogin} = useContext(isLoginContext);
    const [totalPage,setTotalPage] = useState(1);
    const [transactionPage,setTransactionPage] = useState(1);
    const [changePasswordModalShow,setChangePasswordModal] = useState(false);    
    const [layoutIndex,setLayoutIndex] = useState(0);
    const [fromId,setFromId] = useState('');
    const [toId,setToId] = useState('')
    const history = useHistory();        
    const layouts = ['topCenter','top', 'topLeft', 'topRight', 'center', 'centerLeft', 'centerRight', 'bottom', 'bottomLeft', 'bottomCenter', 'bottomRight']
    const prevPage = function(){        
        if(transactionPage > 1){
            setTransactionPage(transactionPage-1);            
        }
    }

    const nextPage = function(){
        if(transactionPage < totalPage){
            setTransactionPage(transactionPage+1);        
        }
    }

    const goPage = function(){
        setTransactionPage(transactionPage)
    }

    const transferStateText = function(status){
        if(status == 0){
            return '실패';
        }else if(status == 1){
            return '성공';
        }else if(status == 2){
            return '처리중';
        }
    }

    const transferStatusClassTextColor = function(status){
        if(status == 0){
            return 'tl txt_red';
        }else{
            return 'tl txt_blue';
        }
    }

    const submitLayoutIndex = function(){        
        hitbitApi.setUserLayoutIndex({layoutIndex}).then(res=>{
            if(res.statusCode === 200){
                AlertMessage('변경하였습니다.','success');        
            }else{
                AlertMessage(res.message,'success');
            }
        })
    }

    const onKeyPressEnter = function(e){
        if(e.key == 'Enter'){
            loadTransaction();
        }
    }

    const clearSearchLoadApplication = function(){
        setFromId('');        
        setToId('');
        //loadTransaction();

        hitbitApi.transaction({page:transactionPage,from_id:'',to_id:''}).then(res=>{
            if(res.statusCode === 200){
                setList(res.transactions);
                setTotalPage(res.transactionTotalPage);
            }
        })
    }

    const loadTransaction = function(){
        hitbitApi.transaction({page:transactionPage,from_id:fromId,to_id:toId}).then(res=>{
            if(res.statusCode === 200){
                setList(res.transactions);
                setTotalPage(res.transactionTotalPage);
            }
        })
    }


    const logout = function(){

        AlertMessage('로그아웃 하시겠습니까?','warning',(result)=>{
            if(result){
                hitbitApi.logout().then(res=>{
                    setIsLogin(false);
                    history.push('/')
                })
            }
        },{            
            confirmButtonText:'로그아웃',
            cancelButtonText:'취소', 
            showConfirmButton:true,
            showCancelButton:true,
        });        
    }
    useEffect(()=>{
        hitbitApi.transaction({page:transactionPage,from_id:fromId,to_id:toId}).then(res=>{
            if(res.statusCode === 200){
                setList(res.transactions);
                setTotalPage(res.transactionTotalPage);
            }
        })
    },[transactionPage])

    useEffect(()=>{
        hitbitApi.myinfo().then(res=>{            
            if(res.statusCode === 200){
                setInfo(res.info);
                setLayoutIndex(res.info.layoutIndex);
                hitbitApi.transaction({page:transactionPage,from_id:'',to_id:''}).then(res=>{
                    if(res.statusCode === 200){
                        setList(res.transactions);
                        setTotalPage(res.transactionTotalPage);
                    }
                })
            }
            
        });
    },[]);

    return (
        <>
        {changePasswordModalShow == true ? <ChangePasswordModal setLoading={(isShow)=>{setLoading(isShow)}} isShow={changePasswordModalShow} setChangePasswordModal={(isShow)=>setChangePasswordModal(isShow)}/>:null}
        <MainLayout>
        <div className="basic_box mybox">
            <div className="titwrap d-flex justify-content-between">
                <div>내지갑</div>
                <p>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={logout}>로그아웃</button>
                </p>
            </div>
            <ul className="info_list">
                <li>
                <p className="tleft">아이디</p>
                <p className="tright">{info.user_id ?? '로딩중입니다.'}</p>
                </li>
                <li>
                <p className="tleft">지갑주소</p>
                <p className="tright text-break">{info.address ?? '로딩중입니다.'}</p>
                </li>
                <li>
                <p className="tleft">가입일</p>
                <p className="tright">{info.createdAt ? shortDateTime(info.createdAt) : '로딩중입니다.'}</p>
                </li>
                <li>
                    <p className="tleft">Noty위치</p>
                    <p className="tright input-group">
                        
                            <select value={layoutIndex} onChange={(e)=>{setLayoutIndex(e.target.value)}} className="form-control">
                                {layouts.map((value,index)=>{
                                    return (
                                        <option key={"layoutIndex_"+index} value={index}>{value}</option>
                                    )
                                })}   
                            </select>
                            <button type="submit" className="btn btn-primary ml-2" onClick={()=>{submitLayoutIndex()}}>수정</button>
                        
                    </p>
                </li>
            </ul>
            <p>
                <button type="button" className="btn btn-success btn-block" onClick={()=>{setChangePasswordModal(true)}}>패스워드 변경</button>
            </p>
            <div>
            <p>전송내역</p>

            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend"><button className="btn btn-outline-secondary" type="button" onClick={()=>{clearSearchLoadApplication()}}>초기화</button></div>
                    <input type="text" className="form-control text-right" value={fromId} onChange={(e)=>setFromId(e.target.value)} placeholder="검색할 발신자 아이디를 넣어 주세요." onKeyPress={onKeyPressEnter}/>
                    <input type="text" className="form-control text-right" value={toId} onChange={(e)=>setToId(e.target.value)} placeholder="검색할 수신자 아이디를 넣어 주세요." onKeyPress={onKeyPressEnter}/>
                    <div className="input-group-append"><button type="button" className="btn btn-md btn-primary" onClick={()=>loadTransaction()}>검색</button></div>
                </div>                    
            </div>

            <ul className="infobox">
                {list.map((value,index)=>{
                    return (
                        <li key={value.id}>
                            <span>{shortDateTime(value.createdAt)}</span>
                            
                            <p className={transferStatusClassTextColor(value.status)}>전송({transferStateText(value.status)})</p>
                            
                            <p className="tr">{numberFormat(value.amount)}{value.token_id}</p>
                            {value.fee > 0 ? <p className='tr'><small>{numberFormat(value.fee)} ({value.feeRate}%)</small></p>:null}
                            <p className="tl">{value.from_id}</p>
                            <p className="tr">{value.to_id}</p>
                            <p className="tr">{value.content}</p>
                            <span style={{wordBreak:'break-all'}}><small>{value.txHash}</small></span>                            
                        </li>
                    )
                })}                
            </ul>
            </div>
            <Pagination prev={prevPage} next={nextPage} goPage={goPage} setPage={setTransactionPage} totalPage={totalPage} page={transactionPage}/>                
        </div>
        </MainLayout>
        </>
    )
}

export default MyInfo;