import { useState ,useContext,useEffect,useDebugValue} from 'react'
import MainLayout from '../layouts/mainLayout'
import AlertMessage from '../lib/alertMessage'
import HitBitApi from '../lib/hitbitApi'
import {useHistory} from 'react-router-dom'
import {shortDateTime,fullDateTime} from '../lib/time'
import { numberFormat } from '../lib/misc'
import CodeRequestInfo from '../components/codeRequest/codeRequestInfo'
import ModifyTradingTableModal from './modal/modifyTradingTableModal'
import { PreloaderContext } from '../components/preloaderContext'
import Pagination from '../components/pagenation'
import SetTimeoutFunc from '../components/SetTimeoutFunc'

function TradingTable(props){
    const hitbitApi = useContext(HitBitApi)
    const {setLoading} = useContext(PreloaderContext);
    const [tradingItem,setTradingItem] = useState(null)
    const [applications,setApplications] = useState([]);
    const [applicationPage,setApplicationPage] = useState(1);
    const [totalPage,setTotalPage] = useState(1);
    const [statusOption,setStatusOption] = useState('all');
    const [waitingApplicationCount,setWaitingApplicationCount] = useState(0);
    const [completedApplicationTotalAmount,setCompletedApplicationTotalAmount] = useState(0);
    const [fromId,setFromId] = useState('');
    const [content,setContent] = useState('');
    const [note,setNote] = useState('');

    const prevPage = function(){
        if(applicationPage > 1){
            setApplicationPage(applicationPage-1);            
        }
    }

    const nextPage = function(){
        if(applicationPage < totalPage){
        setApplicationPage(applicationPage+1);        
        }
    }

    const goPage = function(){
        setApplicationPage(applicationPage)
    }
    
    const [isModifyTradingTableModalShow,modifyTradingTableModalShow] = useState(false);
    const id = props.match.params.id;
    const history = useHistory();

    const transferStatusClassTextColor = function(status){
        if(status == -1){
            return 'tl txt_red';
        }else{
            return 'tl txt_blue';
        }
    }


    const transferStateText = function(status){
        if(status == 0){
            return '대기중';
        }else if(status == 1){
            return '완료';
        }else if(status == -1){
            return '취소';
        }
    }

    const handleComplete = function(applicationId){
        hitbitApi.completeApplication({aid:applicationId}).then(res=>{
            if(res.statusCode == 200){
                loadApplications();
                AlertMessage('완료 하였습니다.','success');
            }else{
                AlertMessage(res.message);
            }
        });
    }

    const handleCancel = function(applicationId){
        hitbitApi.cancelApplication({aid:applicationId}).then(res=>{
            if(res.statusCode == 200){
                loadApplications();
                AlertMessage('취소 하였습니다.','success');
            }else{
                AlertMessage(res.message);
            }
        });
    }

    const transfer = function(applicationId,amount,from_id){
        
        AlertMessage(from_id+'에게 아래의 수량으로 전송합니다','',(result,value)=>{
            if(result){
                setLoading(true);
                let requestAmount = value.value;
                hitbitApi.transferWithApplication({aid:applicationId,amount:requestAmount}).then(res=>{
                    
                    if(res.statusCode === 200){
                        AlertMessage('전송에 성공하였습니다','success');
                        //loadApplications();
                    }else{
                        AlertMessage(res.message);
                    }
                }).finally(()=>{
                    setLoading(false);
                })
            }
        },{            
            input: 'text',
            inputValue:amount,            
            inputAttributes: {
                autocapitalize: 'off',                                                
            },
            confirmButtonText:'전송',
            cancelButtonText:'취소',
            showConfirmButton:true,
            showCancelButton:true,
        });
    }

    const loadApplications = function(){        
        
        hitbitApi.loadApplication({tid:id,page:applicationPage,statusOption,fromId,content}).then(res=>{
            if(res.statusCode === 200){
                setApplications(res.applications);
                setTotalPage(res.applicationTotalPage);
            }
        });
    }      
    
    const clearSearchLoadApplication = function(){
        setFromId('');        
    }

    const clearSearchLoadApplication2 = function(){
        setContent('');
    }

    const onKeyPressEnter = function(e){
        if(e.key == 'Enter'){
            loadApplications();
        }
    }

    const updateTradingTableNote = function(){
        
        setLoading(true);
        hitbitApi.updateTradingTableNote({id,note}).then(res=>{
            if(res.statusCode === 200){
                AlertMessage("수정하였습니다.","success",()=>{
                    
                });                
            }
        }).finally(()=>{
            setLoading(false);
        });
    }

    const reloadTradingTableItem = function(){
        
        setLoading(true);
        hitbitApi.tradingTableFrom({id,page:applicationPage,statusOption,fromId,content}).then(res=>{
            if(res.statusCode === 200){
                if(res.tradingItem){
                    setTradingItem(res.tradingItem);
                    setApplications(res.applications);
                    setTotalPage(res.applicationTotalPage);
                }else{
                    AlertMessage("정보를 찾을 수 없습니다.","warning",()=>{
                        history.push('/main');
                    });
                    setTradingItem({});
                }
            }else{
                AlertMessage("정보를 찾을 수 없습니다.","warning",()=>{
                    history.push('/main');
                });                
            }
        }).finally(()=>{
            setLoading(false);
        });
    };

    useEffect(()=>{
        loadApplications();
    },[applicationPage,statusOption])

    SetTimeoutFunc(()=>{        
        hitbitApi.getWaitingApplicationCount({tid:id}).then(result => {
            
            if(result.statusCode && result.statusCode == 200){
                setWaitingApplicationCount(result.waitingApplicationCount);
                setCompletedApplicationTotalAmount(result.totalAmount);
            }
        }).catch(e=>{            
            console.log(e)
        })                
    },6000);


    useEffect(()=>{
        hitbitApi.tradingTableFrom({id,page:applicationPage,statusOption}).then(res=>{
            if(res.statusCode === 200){
                if(res.tradingItem){
                    setTradingItem(res.tradingItem);
                    setApplications(res.applications);
                    setNote(res.tradingItem.note);
                }else{
                    AlertMessage("정보를 찾을 수 없습니다.","warning",()=>{
                        history.push('/main');
                    });
                    setTradingItem({});
                }
            }else{
                AlertMessage("정보를 찾을 수 없습니다.","warning",()=>{
                    history.push('/main');
                });                
            }
        });
    },[])

    return (        
        <>
        {isModifyTradingTableModalShow && <ModifyTradingTableModal setLoading={(isShow)=>{setLoading(isShow)}} isModifyTradingTableModalShow={isModifyTradingTableModalShow} tradingItem={tradingItem} modifyTradingTableModalShow={(isShow,updated)=>{            
            if(updated){
                
                reloadTradingTableItem();
            }
            modifyTradingTableModalShow(isShow)
        }}/>}
        <MainLayout>            
            <div className="basic_box mybox">
                {tradingItem ? <CodeRequestInfo tradingitem={tradingItem} reloadTradingTableItem={()=>{
                    setStatusOption('all');
                    setApplicationPage(1);
                    //reloadTradingTableItem()
                    
                    }}/>:null}
                <button type="button" className="btn btn-primary btn-block" onClick={()=>{modifyTradingTableModalShow(true)}}>수정</button>
            </div>
            <div className="form-control">
                <div className="mb-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="statusOption" value="all" checked={statusOption == 'all'} onChange={ (e)=>{setStatusOption('all')}}/>
                        <label className="form-check-label" for="inlineRadio1">전체</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio"  name="statusOption" value="0" checked={statusOption == '0'}  onChange={e=>{setStatusOption('0')}}/>
                        <label className="form-check-label" for="inlineRadio2">대기중 (<b> {waitingApplicationCount} </b>)</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio"  name="statusOption" value="1" checked={statusOption == '1'}  onChange={e=>{setStatusOption('1')}}/>
                        <label className="form-check-label" for="inlineRadio3">완료</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio"  name="statusOption" value="-1" checked={statusOption == '-1'}  onChange={e=>{setStatusOption('-1')}}/>
                        <label className="form-check-label" for="inlineRadio3">취소</label>
                    </div>
                    <div className="float-right">
                    {numberFormat(completedApplicationTotalAmount)} {tradingItem ? <small>{tradingItem.token_id}</small>:null}
                    </div>
                </div>
            </div>

            
                <div className="form-group">
                    <div className="input-group mb-3">
                        
                        <input type="text" className="form-control text-right" value={note} onChange={(e)=>setNote(e.target.value)} placeholder="검색할 아이디를 넣어 주세요."/>
                        <div className="input-group-append"><button type="button" className="btn btn-md btn-primary" onClick={()=>updateTradingTableNote()}>설정</button></div>
                    </div>                    
                </div>

                <div className="form-group">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend"><button className="btn btn-outline-secondary" type="button" onClick={()=>{clearSearchLoadApplication()}}>초기화</button></div>
                        <input type="text" className="form-control text-right" value={fromId} onChange={(e)=>setFromId(e.target.value)} placeholder="검색할 아이디를 넣어 주세요." onKeyPress={onKeyPressEnter}/>
                        <div className="input-group-append"><button type="button" className="btn btn-md btn-primary" onClick={()=>loadApplications()}>검색</button></div>
                    </div>                    
                </div>

                <div className="form-group">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend"><button className="btn btn-outline-secondary" type="button" onClick={()=>{clearSearchLoadApplication2()}}>초기화</button></div>
                        <input type="text" className="form-control text-right" value={content} onChange={(e)=>setContent(e.target.value)} placeholder="검색어를 넣어 주세요"  onKeyPress={onKeyPressEnter}/>
                        <div className="input-group-append"><button type="button" className="btn btn-md btn-primary" onClick={()=>loadApplications()}>검색</button></div>
                    </div>                    
                </div>
            
            <div className="basic_box ">              
            {applications.length > 0 && 
            
                 
                <ul className="infobox">
                    {applications.map((value,index)=>{
                        return (
                            <li key={value.id} style={value.recentCount > 0 ? {border:"1px solid #ff9f9f"}:null}>
                                <span>{shortDateTime(value.createdAt)} - {value.code ?? <small>({value.code})</small>}</span>                                
                                <p className={transferStatusClassTextColor(value.status)}>{transferStateText(value.status)} {value.recentCount > 0 ? `(${value.recentCount})`:null}</p>                                
                                <p className="tr">{numberFormat(value.amount)}{value.token_id} {(value.status == 0 && value.action === 'buy') && <button className="btn btn-outline-primary btn-sm" onClick={()=>{transfer(value.id,value.amount,value.from_id);}}>전송</button>}</p>                                
                                <p className="tl"><a onClick={()=>{setFromId(value.from_id)}}>{value.from_id}</a></p>
                                <p className="tr">{value.to_id}</p>                                
                                <p className="tl"><small>전송시간</small></p>
                                <p className="tr"><small>{value.transferedAt != null ? fullDateTime(value.transferedAt):'미전송' }</small></p>
                                <span style={{wordBreak:'break-all'}}><small>{value.content}</small></span>                                
                                {value.status == 0 ? <>
                                <p className="tl">
                                    {value.transferedAt == null ? 
                                        <button type="button" className="btn btn-outline-danger btn-sm" onClick={()=>{handleCancel(value.id)}}>취소</button>:null
                                    }                                    
                                </p>
                                <p className="tr"><button type="button" className="btn btn-primary btn-sm"  onClick={()=>{handleComplete(value.id)}}>완료</button></p>
                                </>
                                : value.status == 1 ? <span className="text-center text-success">완료된 거래입니다. <br/><small>{fullDateTime(value.createdAt)}</small></span>:<span  className="text-center text-danger">취소된 거래입니다.<br/><small>{fullDateTime(value.toCanceledAt)}</small></span>}
                            </li>
                        )
                    })}                
                </ul>
                
            }
            <Pagination prev={prevPage} next={nextPage} goPage={goPage} setPage={setApplicationPage} totalPage={totalPage} page={applicationPage}/>                   
            </div>
             
            
        </MainLayout>
        </>
    );
}

export default TradingTable