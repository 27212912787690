import {useEffect,useRef} from 'react'

function SetTimeoutFunc(cb,delay){
        const callback = useRef(cb);

        useEffect(()=>{
            callback.current = cb;
        },[cb])

        useEffect(()=>{
            let id = null;
            function tick(){
                const ret = callback.current();

                if(ret instanceof Promise){
                    ret.then(()=>{
                        if(delay !== null){
                            id = setTimeout(tick,delay);
                        }
                    });
                }else{
                    if(delay !== null){
                        id = setTimeout(tick,delay);
                    }
                }
            }

            if(delay !== null){
                id = setTimeout(tick,delay);
                return ()=> id && clearTimeout(id);
            }
        },[delay]);

}

export default SetTimeoutFunc