import Logo from '../assets/img/logo.jpg'
import {Link} from 'react-router-dom'

function Header(){
    return (

        <header id="header" className="fixed-top">
            <div className="container-fluid d-flex justify-content-between align-items-center">
                <h1 className="logo"><Link to="/main" className="logo mr-auto"><img src={Logo} alt="" /></Link></h1>
                <div className="header-util"> <Link to='/myinfo' className="i_user">사용자정보</Link> </div>
            </div>
        </header>
    )
}

export default Header