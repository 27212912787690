import React,{useState,useEffect,useContext} from 'react'
import {useHistory,Link} from 'react-router-dom'
import HitBitApi from '../lib/hitbitApi'
import BuyModal from './modal/buyModal'
import SellModal from './modal/sellModal'
import SendModal from './modal/sendModal'
import { PreloaderContext } from '../components/preloaderContext'
import { isLoginContext } from '../components/isLoginContext'
import {shortDateTime} from '../lib/time'
import {tradingTableAmount,parseCode,numberFormat} from '../lib/misc'
import MainLayout from '../layouts/mainLayout'
import SetTimeoutFunc from '../components/SetTimeoutFunc'
import AlertMessage from '../lib/alertMessage'

function Main(){    
    const hitbitApi = useContext(HitBitApi)
    const {setLoading} = useContext(PreloaderContext);
    const {isLogin,setIsLogin} = useContext(isLoginContext);
    const [coins,setCoins] = useState([])
    const [tokens,setTokens] = useState([])
    const [finishedTable,setFinishedTable] = useState([]);
    const [buyModalShow,setBuyModalShow] = useState(false);    
    const [sellModalShow,setSellModalShow] = useState(false);    
    const [sendModalShow,setSendModalShow] = useState(false);    
    const [buyTable,setBuyTable] = useState([])
    const [sellTable,setSellTable] = useState([])
    const [firstTrigger,setFirstTrigger] = useState(false);
    const [code,setCode] = useState('');

    const history = useHistory();

    const titleAction = function(action){
        if(action === 'buy'){
            return '구매';
        }else if(action === 'sell'){
            return '판매'
        }

        return action;
    }

    const openCode = function(){
        
        let parsedCode = code.trim();
        setCode(parsedCode);
        if(!parsedCode){            
            AlertMessage('code를 입력해 주세요.');
            return ;
        }

        if(!/^[A-Z-a-z]+[01]\w+/.test(code)){
            AlertMessage('code의 형식을 확인해 주세요.');
            return ;
        }

        let pp = parseCode(code);

        history.push('/request/code/'+parsedCode);
    }

    const userInfo = function(){        
        setLoading(true);
        hitbitApi.user().then(result => {
            if(!isLogin){
                setIsLogin(true);
            }              
            setCoins(result.user.balances);
            
        }).catch(e=>{            
            if(e.response){
                if(e.response.hasOwnProperty('status')){
                    if(e.response.status === 401){
                        setIsLogin(false);
                        history.push('/');
                    }
                }                
            }            
        }).finally(()=>{

            
            let a = new Promise((resolve,reject)=>{
                hitbitApi.coins().then(result => {                
                    setTokens(result.tokens);
                }).finally(()=>{
                    resolve(true)
                })
            }) 

            let b = new Promise((resolve,reject)=>{
                hitbitApi.tradingTable().then(result=>{
                    if(result.statusCode === 200){
                        setBuyTable(result.buy);
                        setSellTable(result.sell);
                        setFinishedTable(result.closed);
                    }
                }).finally(()=>{
                    resolve(true)
                })
            }) 

            Promise.all([a,b]).finally(()=>{
                setLoading(false);
                setFirstTrigger(true);
            })
        })
    }
    
    useEffect(()=>{        
        userInfo();                
    },[])
        
    SetTimeoutFunc(()=>{
        if(firstTrigger){
            hitbitApi.user().then(result => {
                setCoins(result.user.balances);
                
            }).catch(e=>{            
                if(e.response){
                    if(e.response.hasOwnProperty('status')){
                        if(e.response.status === 401){
                            setIsLogin(false);
                            history.push('/');
                        }
                    }                
                }            
            })
        }
        
    },6000);
    
    return (
        <>
        <BuyModal setLoading={(isShow)=>{setLoading(isShow)}} isShow={buyModalShow} tokens={tokens} setBuyModalShow={(isShow)=>setBuyModalShow(isShow)}/>
        <SellModal setLoading={(isShow)=>{setLoading(isShow)}} isShow={sellModalShow} tokens={tokens} setSellModalShow={(isShow)=>setSellModalShow(isShow)}/>
        <SendModal setLoading={(isShow)=>{setLoading(isShow)}} isShow={sendModalShow} tokens={tokens} setSendModalShow={(isShow)=>setSendModalShow(isShow)}/>
        
        <MainLayout>

        <div className="holdcoin_box">
            <div className="c_tit">보유코인 <Link className="i_refresh01">새로고침</Link></div>
            <div className="coin">
                <ul className="coin_list">
                {coins.map((value,index)=>{
                        return (
                            <li key={"tokens_"+value.token_id}>
                                <p className="type">{value.token_name}<span>({value.token_id})</span></p>                                
                                <p className="num">{numberFormat(value.balance)} {value.token_id}<span>{typeof value.feeBalance !== 'undefined' ? (numberFormat(value.feeBalance)):"보유수량"}</span><small><Link to={'/tokens/' + value.token_id}>내역보기 <i className="icofont-long-arrow-right"></i></Link></small></p>
                            </li>
                        )})
                }
                                
                </ul>
                <div className="btnbox"><Link className="submit" onClick={()=>{
                    setSendModalShow({isShow:true})
                }}><span className="icon"></span>전송하기</Link></div>                
            </div>
        </div>

        <div className="basic_box codeinput">
            <small className="c_tit">코드를 입력해 주세요.</small>
            <input type="text" className="form-control" onChange={(e)=>{setCode(e.target.value)}}/>
            <button type='button' className="btn btn-primary btn-block mt-2" onClick={openCode}> <span className="icon"></span>코드 거래</button>
        </div>
        <div className="basic_box sale">
            <div className="titwrap d-flex justify-content-between">
                <div>내 판매거래 <Link className="i_refresh02">새로고침</Link></div>
                <p>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={()=>{
                                setSellModalShow({isShow:true})}
                            }>판매신청</button>
                </p>
            </div>
            <ul className="coin_list">

                { sellTable.length > 0 && sellTable.map((value,index)=>{
                    return (
                        <li key={`sell_${value.id}`}>
                            <Link to={"/tradingTable/"+value.id}>
                            <p className="type">{value.token_name}({value.token_id})<span className="date">{shortDateTime(value.createdAt)}</span></p>
                            <p className="num">{tradingTableAmount(value.amount)}<span>{value.title}</span></p>
                            </Link>
                        </li>
                    )
                })}   

                {sellTable.length < 1 && <li className="empty_text">거래내역이 없습니다.</li>}
            </ul>
        </div>

        <div className="basic_box deal">
            <div className="titwrap d-flex justify-content-between">
                <div>내 구매거래 <Link className="i_refresh02">새로고침</Link></div>
                <p>
                <button type="button" className="btn btn-outline-primary btn-sm"  onClick={()=>{
                                setBuyModalShow({isShow:true})}
                            }>구매신청</button>
                </p>
            </div>
            <ul className="coin_list">

                {buyTable.length > 0 && buyTable.map((value)=>{
                    return (
                        <li key={`buy_${value.id}`}>
                            <Link to={"/tradingTable/"+value.id}>
                            <p className="type">{value.token_name}({value.token_id})<span className="date">{shortDateTime(value.createdAt)}</span></p>
                            <p className="num">{tradingTableAmount(value.amount)}<span>{value.title}</span></p>
                            </Link>
                        </li>
                    )
                })}

                {buyTable.length < 1 && <li className="empty_text">거래내역이 없습니다.</li>}
            </ul>
        </div>
        
        <div className="basic_box sale">
            <div className="titwrap d-flex justify-content-between">
                <div>종료된 거래 <Link className="i_refresh02">새로고침</Link></div>                
            </div>
            <ul className="coin_list">

                { finishedTable.length > 0 && finishedTable.map((value,index)=>{
                    return (
                        <li key={`finished_${value.id}`}>
                            <p className="type">{titleAction(value.action)} - {value.token_name}({value.token_id})<span className="date">{shortDateTime(value.createdAt)}</span></p>
                            <p className="num">{tradingTableAmount(value.amount)}<span>{value.title}</span></p>
                        </li>
                    )
                })}   

                {finishedTable.length < 1 && <li className="empty_text">거래내역이 없습니다.</li>}
            </ul>
        </div>
            
        </MainLayout>        
        </>
    )
}

export default Main
