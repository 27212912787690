import React,{useContext, useState} from 'react'
import {Link,useHistory} from 'react-router-dom'
import HitBitApi from '../lib/hitbitApi'
import AlertMessage from '../lib/alertMessage'
import { PreloaderContext } from '../components/preloaderContext'
import {isLoginContext} from '../components/isLoginContext'

function Login(){
    const [user_id,setUserId] = useState('');
    const [password,setPassword] = useState('');
    const hitbitApi = useContext(HitBitApi)
    const {setLoading} = useContext(PreloaderContext);
    const {setIsLogin} = useContext(isLoginContext);
    const history = useHistory();

    const handleFindPassword = ()=>{
        AlertMessage("연락처를 입력해 주세요.변경된 패스워드를 문자로 전송합니다.",'warning',(result,value)=>{
            if(result){
                hitbitApi.resetPassword({mob:value.value}).then((res)=>{
                    AlertMessage(res.message);
                }).catch(e=>{
                    if(e.response){
                        if(e.response.status === 429){
                            AlertMessage("짧은 시간에 많이 시도 하셨습니다. 1분후에 다시 시도해 주세요.");
                        }else{
                            AlertMessage(e.response.message);
                        }
                    }
                })
            }
        },{            
            input: 'text',
            inputValue:'',            
            inputAttributes: {
                autocapitalize: 'off',                                                
            },
            confirmButtonText:'신청',
            cancelButtonText:'취소',
            showConfirmButton:true,
            showCancelButton:true,
        });
    }

    const handleLogin = ()=>{
        setLoading(true)
        hitbitApi.login({user_id,password}).then(({success,message})=>{
            if(success){    
                setIsLogin(true);
                history.push('/main');
            }else{
                AlertMessage(message);
            }
        }).catch(e=>{
            if(e.response){
                if(e.response.status === 429){
                    AlertMessage("짧은 시간에 많이 시도 하셨습니다. 1분후에 다시 시도해 주세요.");
                }else{
                    AlertMessage(e.response.message);
                }
            }
        })        
        .finally(()=>{
            setLoading(false);
        })
    }

    const inputUserId = function(e){
        setUserId(e.target.value);
    }

    const inputPassword = function(e){
        setPassword(e.target.value);
    }  

    return (
        <section>
            <div className="container">
            <section id="login" className="login">
                <div className="container">
                <div className="row">
                    <div className="col-md-5 mx-auto">                    
                    <div id="login">
                        <div className="myform form ">
                        <div className="mb-3">
                            <div className="section-title">
                                <h2>LOGIN</h2>
                                <h3>No.1 가상자산 플랫폼, <span>Hitbit</span></h3>
                                </div>
                            </div>
                            <form>
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">아이디</label>
                            <input type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="회원님의 아이디를 입력해주세요" onChange={inputUserId}/>
                            </div>
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">패스워드</label>
                            <input type="password" name="password" id="password" className="form-control" aria-describedby="emailHelp" placeholder="비밀번호를 입력해주세요"  onChange={inputPassword}/>
                            </div>
                            <div className="col-md-12 text-center ">
                            <button type="button" onClick={handleLogin} className=" btn btn-block mybtn btn-primary tx-tfm">로그인</button>
                            
                            <p className="mt-2">
                                <small className="text-primary" onClick={handleFindPassword}>패스워드 찾기 </small>
                            </p>
                            
                            </div>
                            <div className="form-group mt-4">
                            <p className="text-center">회원님의 계정이 없으신가요? <Link id="signup" to="/join">회원가입</Link></p>
                            </div>
                            <div className="form-group mt-4">
                                <p className="text-center"><a target="_blank" href='https://t.me/joinchat/9UnDNye_L8NkYTQ1' rel="noreferrer">@고객센터</a></p>
                            </div>
                            </form>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
            </section>

            </div>
        </section>

    )
}

export default Login
