import Sound from 'react-sound'
import TheOterSide from '../assets/sound/4.mp3'

function PlaySound4 (props){
    const {handleSongLoading,
        handleSongPlaying,
        handleSongFinshedPlaying,
        isPlaying} = props;
    return (
        <div>
            <Sound 
                url={TheOterSide}
                playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
                onLoading={handleSongLoading}
                onPlaying={handleSongPlaying}
                onFinishedPlaying={handleSongFinshedPlaying}
            />            
        </div>
    )
}

export default PlaySound4