import HitBitApi from '../lib/hitbitApi'
import MainLayout from '../layouts/mainLayout'
import { useEffect, useState,useContext } from 'react'
import {shortDateTime,fullDateTime} from '../lib/time'
import AlertMessage from "../lib/alertMessage"
import Pagination from '../components/pagenation'
import { PreloaderContext } from '../components/preloaderContext'
import {numberFormat} from '../lib/misc'
import time from '../lib/time'

function Tokens(props){
    const hitbitApi = useContext(HitBitApi)            
    const [list,setList] = useState([]);    
    const [totalPage,setTotalPage] = useState(1);
    const [transactionPage,setTransactionPage] = useState(1);
    const [startDate,setStartDate] = useState(time.onlyDate(time.date()));
    const [endDate,setEndDate] = useState(time.onlyDate(time.date()));
    const [sentTransfer,setSendTransfer] = useState(0);
    const [receivedTransfer,setReceivedTransfer] = useState(0);
    const {setLoading} = useContext(PreloaderContext);
    

    const prevPage = function(){        
        if(transactionPage > 1){
            setTransactionPage(transactionPage-1);            
        }
    }

    const nextPage = function(){
        if(transactionPage < totalPage){
            setTransactionPage(transactionPage+1);        
        }
    }

    const goPage = function(){
        setTransactionPage(transactionPage)
    }    

    const transferStatusClassTextColor = function(status){
        if(status == -1){
            return 'tl txt_red';
        }else{
            return 'tl txt_blue';
        }
    }


    const transferStateText = function(status){
        if(status == 0){
            return '대기중';
        }else if(status == 1){
            return '완료';
        }else if(status == -1){
            return '취소';
        }
    }

    const searchDate = function(){
        if(transactionPage == 1){
            requestList();
        }else{
            setTransactionPage(1);
        }
        
    }

    const requestList = function(){
        setLoading(true);
        hitbitApi.getApplicationWithToken({token_id:props.match.params.token_id,startDate,endDate,page:transactionPage}).then(res=>{
            
            if(res.statusCode === 200){
                setSendTransfer(res.transferedAmount);
                setReceivedTransfer(res.receivedAmount);
                setList(res.applications);
                setTotalPage(res.applicationTotalPage);
            }else{
                AlertMessage(res.message);
            }
        }).finally(()=>{
            setLoading(false);
        });
    }

    useEffect(()=>{
        requestList();
    },[transactionPage])

    useEffect(()=>{
        requestList();
    },[]);

    return (
        <>        
        <MainLayout>
        <div className="basic_box deal">
            <div className="titwrap d-flex justify-content-between">
                <div><b>{props.match.params.token_id}</b> 내역</div>                
            </div>
            <ul className="info_list">
                <li>
                <p className="tleft">송신수량</p>
                <p className="tright">{numberFormat(sentTransfer)} {props.match.params.token_id}</p>
                </li>
                <li>
                <p className="tleft">수신수량</p>
                <p className="tright text-break">{numberFormat(receivedTransfer)} {props.match.params.token_id}</p>
                </li>
            </ul>            
            <div>
            <p>송수신 내역</p>
            <div className="form-group  mb-3 row">                            
                    <div className="col-12 col-sm-6">
                        <input type="date" className="form-control text-right" value={startDate} onChange={(e)=>{setStartDate(e.target.value)}}/>                        
                    </div>             
                    <div className="col-12 col-sm-6">
                        <input type="date" className="form-control text-right" value={endDate} onChange={(e)=>{setEndDate(e.target.value)}}/>    
                    </div>            

                    
            </div>        
            <div className="row">
            <div className="col-12">                
                    <button type="button" className="btn btn-md btn-primary btn-block" onClick={searchDate}>검색하기</button>
                    </div>
            </div>
            {list.length > 0 && 
                  <>
                <ul className="infobox">
                    {list.map((value,index)=>{
                        return (
                            <li key={value.id}>
                                <span>{shortDateTime(value.createdAt)} - {value.code ?? <small>({value.code})</small>}</span>                                
                                <p className={transferStatusClassTextColor(value.status)}>{transferStateText(value.status)}</p>                                
                                <p className="tr">{numberFormat(value.amount)} {value.token_id} {(value.status == 0 && value.action === 'buy') && null}</p>                                
                                <p className="tl">{value.from_id}</p>
                                <p className="tr">{value.to_id}</p>                                
                                <p className="tl"><small>전송시간</small></p>
                                <p className="tr"><small>{value.transferedAt != null ? fullDateTime(value.transferedAt):'미전송' }</small></p>
                                <span style={{wordBreak:'break-all'}}><small>{value.content}</small></span>                                
                                {value.status == 0 ? null                                                            
                                : value.status == 1 ? <span className="text-center text-success">완료된 거래입니다. <br/><small>{fullDateTime(value.createdAt)}</small></span>:<span  className="text-center text-danger">취소된 거래입니다.<br/><small>{fullDateTime(value.canceledAt)}</small></span>}
                            </li>
                        )
                    })}                
                </ul>
                <Pagination prev={prevPage} next={nextPage} goPage={goPage} setPage={setTransactionPage} totalPage={totalPage} page={transactionPage}/>                   
                    </>
            }               
            </div>
        </div>
        </MainLayout>
        </>
    )
}

export default Tokens;