
import { useContext,useState } from "react"
import ModalBackDrop from "../../components/modalBackdrop"
import HitBitApi from '../../lib/hitbitApi'
import AlertMessage from "../../lib/alertMessage"

function ModifyTradingTableModal(props){
    const hitbitApi = useContext(HitBitApi)    
    
    const id = props.tradingItem.id;
    const [title,setTitle] = useState(props.tradingItem.title);
    const [amount,setAmount] = useState(props.tradingItem.amount);
    const [minPrice,setMinPrice] = useState(props.tradingItem.minPrice);
    const [maxPrice,setMaxPrice] = useState(props.tradingItem.maxPrice);
    const [content,setContent] = useState(props.tradingItem.content);
    
    const [status,setStatus] = useState(props.tradingItem.status);
    const [enableShowMemo,setEnableShowMemo] = useState(props.tradingItem.enableShowMemo)
    const [memo,setMemo] = useState(props.tradingItem.memo)
    const [enableMarkDown,setEnableMarkDown] = useState(props.tradingItem.enableMarkDown)
    
    const statusString = [
        "종료됨",
        "거래 가능",
        "중지"
    ];

    const enableShowMemoString = [
      "미노출",
      "노출"
    ];

    const enableMarkDownString = [
      "비활성",
      "활성"
    ];

    const submit = function(){
      props.setLoading(true);
      hitbitApi.modifyTradingTable({   
        id,
        minPrice,
        maxPrice,
        title,
        content,
        amount,
        status,
        memo,
        enableShowMemo,
        enableMarkDown,
      }).then(res=>{          
          
          if(res.statusCode === 200){
            props.modifyTradingTableModalShow(false,true);
            AlertMessage(res.message,'success');
          }else{
            AlertMessage(res.message);
          }
      }).finally(()=>{
        props.setLoading(false);
      });
    }

    
    return (
        props.isModifyTradingTableModalShow &&
        <>
        <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" style={{'display':'block'}}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="pop_head"> 

                <div className="titlewrap d-flex justify-content-between">
                  <p className="pop_title">수정</p>
                  <button type="button" className="btn btn-default text-white btn-sm" onClick={()=>{props.modifyTradingTableModalShow(false)}}>닫기</button>
                </div>

              </div>
              
              <div className="pop_content">
                <table className="list_tb03">
                  <colgroup>
                  <col style={{width:'30%'}} />
                  <col style={{width:"70%"}} />
                  </colgroup>
                  <tbody>                    
                    <tr>
                      <th scope="row">제목</th>
                      <td><input type="text" className="form-control" placeholder="제목을 입력해 주세요." value={title} onChange={(e)=>{setTitle(e.target.value)}}/></td>
                    </tr>
                    <tr>
                      <th scope="row"><label htmlFor="sum">거래수량</label></th>
                      <td><input type="number" step='1' className="form-control" placeholder="미 입력시 무제한"  value={amount}  onChange={(e)=>{setAmount(e.target.value)}}/></td>
                    </tr>
                    <tr>
                      <th scope="row"><label htmlFor="Spread">가격 범위</label></th>
                      <td><input type="number" className="form-control" placeholder="1"  value={minPrice} onChange={(e)=>{setMinPrice(e.target.value)}}/><input type="number" value={maxPrice}  className="form-control" placeholder="2" onChange={(e)=>{setMaxPrice(e.target.value)}}/></td>
                    </tr>
                    <tr>
                      <th scope="row"><label htmlFor="cont">내용</label></th>
                      <td><textarea className="form-control" rows="5" placeholder="" onChange={(e)=>{setContent(e.target.value)}} value={content}>                          
                        </textarea></td>
                    </tr>
                    <tr>
                        <th scope="row">상태</th>
                        <td>
                            <select className="form-control" onChange={(e)=>{setStatus(e.target.value)}} value={status}>
                            {statusString.map((value,index)=>{
                                return (
                                  <option key={index} value={index} >{value}</option>
                                )
                            })}
                            </select>  
                        </td>
                    </tr>
                    <tr>
                      <th scope="row">메모</th>
                      <td><input type="text" className="form-control" placeholder="메모 내용을 입력해 주세요." value={memo} maxLength="200" onChange={(e)=>{setMemo(e.target.value)}}/></td>
                    </tr>
                    <tr>
                        <th scope="row">메모노출</th>
                        <td>
                            <select className="form-control" onChange={(e)=>{setEnableShowMemo(e.target.value)}} value={enableShowMemo}>
                            {enableShowMemoString.map((value,index)=>{
                                return (
                                  <option key={index} value={index} >{value}</option>
                                )
                            })}
                            </select>  
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">MarkDown활성</th>
                        <td>
                            <select className="form-control" onChange={(e)=>{setEnableMarkDown(e.target.value)}} value={enableMarkDown}>
                            {enableMarkDownString.map((value,index)=>{
                                return (
                                  <option key={index} value={index} >{value}</option>
                                )
                            })}
                            </select>  
                        </td>
                    </tr>
                    
                  </tbody>
                </table>
                
                <div className="float-right mt-3">
                  <button type="button" className="btn btn-outline-primary btn-sm" onClick={submit}>수정</button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <ModalBackDrop/>        
        </>
    )

}


export default ModifyTradingTableModal
