import { createContext } from 'react'
import axios from 'axios'

class HitBitApi{
  constructor(){


    
    //axios.defaults.baseURL = "https://site.hitbit.test:9999";
    axios.defaults.baseURL = "https://api.hitp2p.com";
    axios.defaults.withCredentials = true;
    axios.interceptors.response.use(
      (response)=>{
        return response;
      },
      (originalError)=>{
        const {config} = originalError;
        const originalRequest = config;        
        if(originalError.response){
          if(originalError.response && originalError.response.status === 401){
            return new Promise((resolve,reject)=>{          
              this.refresh().then(res=>{              
                if(res === true){
                  resolve(axios(originalRequest))
                }else{
                  reject(originalError);
                }
              })
            });
          }
        }
        
        return Promise.reject(originalError)
      }
    )
    
          
  }

/**
 * 
 * 
 * @param {*} user_id 사용자 아이디
 *            password 
 * @returns success true / false
 */
  login({user_id,password}){
    return axios.post('/user/login',{
        user_id,
        password
      }).then(res=>{
        let {statusCode,access_token} = res.data;
          if(statusCode && statusCode === 200){
            axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;            
            return {success:true,message:'ok'};
          }else{
            return {success:false,message:res.data.message};
          }
          
      })
  }

  changePassword({password,originalPassword,passwordConfirmation}){
    return axios.put('/user/changePassword',{password,originalPassword,passwordConfirmation}).then(res=>{
      return res.data;
    })
  }

  logout(){
    return axios.post('/user/logout',{}).then(res=>{
      delete axios.defaults.headers.common['Authorization'];
      return res;
    });
  }

  refresh(){
    return axios.put('/user/refreshToken').then(res=>{
      
        let {statusCode,access_token} = res.data;
        if(statusCode && statusCode === 200){
          axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
          return true;
        }else{
          // 세션 아웃
          return false;
        }
      })
  }
  coins(){
    return axios.get('/token').then(res=>{      
      return res.data;
    })
  }
  // get user
  user(){
    return axios.get('/user').then(res=>{
      return res.data;
    });
  }

  myinfo(){
    return axios.get('/user/info').then(res=>{
      return res.data;
    })
  }

    // get user
  tradingTable(){
      return axios.get('/tradingTable').then(res=>{
        return res.data;
      });
  }

  buyTradingTable(){
    return axios.get('/tradingTable/buy').then(res=>{
        return res.data;
      });
  }

  sellTradingTable(){
    return axios.get('/tradingTable/sell').then(res=>{
        return res.data;
      });
  }

  transfer({target,amount,token_id}){
    return axios.post('/transaction',{target,amount,token_id}).then(res=>{
      return res.data;
    })
  }

  transaction({page,from_id,to_id}){
    return axios.get('/transaction'+'?page='+page+'&from_id='+from_id+"&to_id="+to_id).then(res=>{
      return res.data;
    })
  }
  

  buyTrade({token_id,      
    minPrice,
    maxPrice,
    title,
    content,
    amount}){
    return axios.post('/tradingTable/buy',{token_id,        
      minPrice,
      maxPrice,
      title,
      content,
      amount}).then(res=>{
      return res.data;
    })
  }

  sellTrade({token_id,      
    minPrice,
    maxPrice,
    title,
    content,
    amount}){
    return axios.post('/tradingTable/sell',{token_id,        
      minPrice,
      maxPrice,
      title,
      content,
      amount}).then(res=>{
      return res.data;
    })
  }

  codeRequest({code}){
    return axios.get('/tradingTable/code/'+code).then(res=>{
      return res.data;
    })
  }

  tradingTableFrom({id,page,statusOption,fromId,content}){
    return axios.get('/tradingTable/tid/'+id+"?page="+page+"&status="+statusOption+"&from_id="+fromId+"&content="+content).then(res=>{
      return res.data;
    })
  }

  newApplication({code,tid,to_id,amount,content,txHash}){
    return axios.post('/application',{code,tid,to_id,amount,content,txHash}).then(res=>{
      return res.data;
    })
  }

  transferWithApplication({aid,amount}){
    return axios.post('/application/transfer/'+aid,{amount}).then(res=>{
      return res.data;
    });
  }

  completeApplication({aid}){
    return axios.put('/application/complete/'+aid).then(res=>{
      return res.data;
    });
  }

  cancelApplication({aid}){
    return axios.put('/application/cancel/'+aid).then(res=>{
      return res.data;
    });
  }

  loadApplication({tid,page,statusOption,fromId,content}){
    return axios.get('/application/tid/'+tid+"?page="+page+"&status="+statusOption+"&from_id="+fromId+"&content="+content).then(res=>{
      return res.data;
    })
  }
  userApplication(){
    return axios.get('/application').then(res=>{
      return res.data;
    })
  }

  getApplicationWithToken({token_id,startDate,endDate,page}){
    return axios.get('/application/token/'+token_id+"?page="+page+"&startDate="+startDate+"&endDate="+endDate).then(res=>{
      return res.data;
    })
  }

  join({user_id,password,passwordConfirmation,mob,code}){
    return axios.post('/user/join',{user_id,password,passwordConfirmation,mob,code}).then(res=>{
      return res.data;
    })
  }

  sendCode({mob}){
    return axios.post('/sms',{mob}).then(res=>{
      return res.data;
    })
  }

  modifyTradingTable({id,minPrice,maxPrice,title,content,amount,status,memo,enableShowMemo,enableMarkDown}){
    return axios.put('/tradingTable/'+id,{minPrice,maxPrice,title,content,amount,status,memo,enableShowMemo,enableMarkDown}).then(res=>{
      return res.data;
    })
  }

  updateTradingTableNote({id,note}){
    return axios.put('/tradingTable/note/'+id,{note}).then(res=>{
      return res.data;
    })
  }

  getNoty(){
    return axios.get('/user/noty').then(res=>{
      return res.data;
    })
  }

  notied({id}){
    return axios.put('/user/noty/'+id).then(res=>{
      return res.data;
    })
  }

  resetPassword({mob}){
    return axios.post('/user/resetPassword',{mob}).then(res=>{

    });
  }

  setUserLayoutIndex({layoutIndex}){
    return axios.put('/user/layoutIndex',{layoutIndex}).then(res=>{
      return res.data;
    })    
  }

  requestFromHash({hashString,code,amount,callback,content}){
    return axios.post('/user/apiAuth',{hashString,code,amount,callback,content}).then(res=>{
          let {statusCode,access_token} = res.data;
          if(statusCode && statusCode === 200){
            axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;            
            return {success:true,message:'ok'};
          }else{
            return {success:false,message:res.data.message};
          }
    })
  }

  getWaitingApplicationCount({tid}){
    return axios.get('/application/waiting/'+tid+"?t="+(new Date()).getTime()).then(res=>{
      return res.data;
    })
  }
}

const HitbitApi = createContext(new HitBitApi())

export default HitbitApi
