
import { useContext,useState } from "react"
import ModalBackDrop from "../../components/modalBackdrop"
import HitBitApi from '../../lib/hitbitApi'
import AlertMessage from "../../lib/alertMessage"

function SellModal(props){
    const hitbitApi = useContext(HitBitApi)    
    

    const [token,setToken] = useState('');
    const [title,setTitle] = useState('');
    const [amount,setAmount] = useState(0);
    const [minPrice,setMinPrice] = useState(0);
    const [maxPrice,setMaxPrice] = useState(0);
    const [content,setContent] = useState('');

    
    const submit = function(){
      props.setLoading(true);
      hitbitApi.sellTrade({token_id:token,      
        minPrice,
        maxPrice,
        title,
        content,
        amount}).then(res=>{          
          
          if(res.statusCode === 200){
            props.setSellModalShow(false);
            AlertMessage(res.message,'success');
          }else{
            AlertMessage(res.message);
          }
      }).finally(()=>{
        props.setLoading(false);
      });
    }

    
    return (
        props.isShow &&
        <>
        <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" style={{'display':'block'}}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="pop_head"> 

                <div className="titlewrap d-flex justify-content-between">
                  <p className="pop_title">판매</p>
                  <button type="button" className="btn btn-default text-white btn-sm" onClick={()=>{props.setSellModalShow(false)}}>닫기</button>
                </div>

              </div>
              
              <div className="pop_content">
                <table className="list_tb03">
                  <colgroup>
                  <col style={{width:'30%'}} />
                  <col style={{width:"70%"}} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">거래 코인</th>
                      <td>
                        <select className="form-control" onChange={(e)=>{setToken(e.target.value)}}>
                        <option>토큰을 선택해 주세요.</option>   
                        {props.tokens.map((value,index)=>{
                        return (
                            <option key={value.contract} value={value.token_id}>{value.token_name}</option>   
                          )
                      })}

                        </select>                                              
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">제목</th>
                      <td><input type="text" className="form-control" placeholder="제목을 입력해 주세요."  onChange={(e)=>{setTitle(e.target.value)}}/></td>
                    </tr>
                    <tr>
                      <th scope="row"><label htmlFor="sum">거래수량</label></th>
                      <td><input type="number" step='1' className="form-control" placeholder="미 입력시 무제한" onChange={(e)=>{setAmount(e.target.value)}}/></td>
                    </tr>
                    <tr>
                      <th scope="row"><label htmlFor="Spread">가격 범위</label></th>
                      <td><input type="number" className="form-control" placeholder="1" onChange={(e)=>{setMinPrice(e.target.value)}}/><input type="number" className="form-control" placeholder="2" onChange={(e)=>{setMaxPrice(e.target.value)}}/></td>
                    </tr>
                    <tr>
                      <th scope="row"><label htmlFor="cont">내용</label></th>
                      <td><textarea className="form-control" rows="5" placeholder="" onChange={(e)=>{setContent(e.target.value)}}>
                          
                          </textarea></td>
                    </tr>
                  </tbody>
                </table>
                
                <div className="float-right mt-3">
                  <button type="button" className="btn btn-outline-primary btn-sm" onClick={submit}>작성완료</button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <ModalBackDrop/>        
        </>
    )

}


export default SellModal
