import {Link} from 'react-router-dom'
 
function Footer(){
    const gotoTop = function(){
        window.scrollTo(0, 0);
    }
    return (

        <footer id="m_footerWrap">
        <div className="topwrap"><Link onClick={gotoTop}>TOP <i className="icofont-simple-up"></i></Link></div>
        <div className="footercon">
            <div className="copyright"> &copy; Copyright <strong><span>Hitbit</span></strong>. All Rights Reserved </div>
        </div>
        </footer>
    )
}

export default Footer