import Noty from 'noty'

function NotyEvents(message,params,cb,layoutIndex=0){    

    const layouts = ['topCenter','top', 'topLeft', 'topRight', 'center', 'centerLeft', 'centerRight', 'bottom', 'bottomLeft', 'bottomCenter', 'bottomRight']

    if(layouts.length <= layoutIndex || layoutIndex < 0){
        layoutIndex = 0;
    }
    
    new Noty({
        type: 'info',
        text: message,
        //layout: 'topCenter',
        layout: layouts[layoutIndex],
        timeout:5000,
        closeWith:['click'],
        callbacks:{
            beforeShow:()=>{
                if(cb){
                    cb('beforeShow',params);
                }
            },            
        }
    }).show();    
}

export default NotyEvents