import { useEffect,useContext,useState } from 'react';
import HitBitApi from '../lib/hitbitApi'
import MainLayout from '../layouts/mainLayout'
import {useHistory} from 'react-router-dom'
import AlertMessage from '../lib/alertMessage';
import CodeRequestInfo from '../components/codeRequest/codeRequestInfo';
import CodeInput from '../components/codeRequest/codeInput';
import ReportedApplication from '../components/codeRequest/reportedApplication';

function CodeRequest(props){
    const hitbitApi = useContext(HitBitApi)
    const [tradingitem,setTradingItem] = useState({});
    const [application,setApplication] = useState(null);
    const code = props.match.params.code;
    const history = useHistory();

    const reloadRequest = function(cb){
        hitbitApi.codeRequest({code}).then(res=>{
            if(res.statusCode === 200){                
                if(res.item){
                    setTradingItem(res.item);
                    setApplication(res.application);
                    if(cb){
                        cb();
                    }
                }else{
                    AlertMessage("정보를 찾을 수 없습니다.","warning",()=>{
                        history.push('/main');
                    });
                    setTradingItem({});
                }
            }else{
                AlertMessage("정보를 찾을 수 없습니다.","warning",()=>{
                    history.push('/main');
                });                
            }
        });
    }
    
    useEffect(()=>{
        hitbitApi.codeRequest({code}).then(res=>{
            if(res.statusCode === 200){                
                if(res.item){
                    setTradingItem(res.item);
                    setApplication(res.application);
                    if(res.item.enableShowMemo){
                        AlertMessage(res.item.memo,"warning");
                    }
                }else{
                    AlertMessage("정보를 찾을 수 없습니다.","warning",()=>{
                        history.push('/main');
                    });
                    setTradingItem({});
                }
            }else{
                AlertMessage(res.message,"warning",()=>{
                    history.push('/main');
                });                
            }
        });
    },[])

    return (        
        <MainLayout>
            <div className="basic_box mybox">
                <div className="titwrap d-flex justify-content-between">
                    <div>코드 정보 : {code}</div>
                </div>
                <CodeRequestInfo tradingitem={tradingitem}/>
                
            </div>
            {tradingitem && application ? <ReportedApplication application={application}/>:<CodeInput reloadRequest={reloadRequest} tradingitem={tradingitem} hitbitApi={hitbitApi} code={code} />}
        </MainLayout>        
    );
}

export default CodeRequest