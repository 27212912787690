import {useState} from 'react'
import {Link} from 'react-router-dom'
import Header from '../components/header'
import Footer from '../components/footer'

const MainLayout = ({children})=>{
    const [visible,setVisible] = useState(false);
    return (
    <>
    
    <Header />
    <div id="contentsWrap">
        <div id="contents">
            {children}
        </div>
         <div className="guide">
            <ul className="guide-list">
            <li> <Link data-toggle="collapse" className="" >유의사항 </Link>
                <div id="guide1" className="collapse show" data-parent=".guide-list">
                <ul>
                    <li>전송시 토큰을 확실하게 입력해 주세요<span className="txt_blue">(토큰 이름과 토큰 심볼을 확인해 주세요!)</span></li>
                    <li>코드와 사용자 아이디를 구분하여 전송해 주세요.</li>
                </ul>
                </div>
            </li>
            </ul>
        </div>
        <div className="floating">
            <ul>
            <li className={visible ? "floating-menu active":"floating-menu"}> <a href="#none" className="open" onClick={()=>{
                setVisible(!visible);
            }}></a>
                
                
                <ul className="floatingHide" style={visible ? {"display":"block"}:{"display":"none"}}>
                    <li><Link to="/buyTable" className="no_br">구매</Link></li>
                    <li><Link to="/sellTable" className="no_br">판매</Link></li>
                    <li><Link to="/application" className="no_br">내거래</Link></li>
                </ul>
                
            </li>
            </ul>
        </div>
    </div>    
    <Footer/>
    </>
)}

export default MainLayout