function Pagination(props){
    
    return (
        <form className="form-inline mt-2 justify-content-end">                    
            <div className="form-group">                        
                <div className="input-group mb-3">                            
                    <div className="input-group-prepend">
                        <button className="btn btn-outline-secondary" type="button" onClick={props.prev}>&lt;</button>
                    </div>
                    <input type="number" className="form-control text-right" step="1" min="1" max={props.totalPage} value={props.page} onChange={(e)=>{props.setPage(e.target.value)}}/>
                    <div className="input-group-append">    
                        <span className="input-group-text">of {props.totalPage}</span>
                    </div>

                    <div className="input-group-append">
                        <button type="button" className="btn btn-md btn-primary" onClick={props.goPage}>GO</button>
                    </div>
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" onClick={props.next}>&gt;</button>
                    </div>
                </div>                                                 
            </div>                    
        </form>
    )
}

export default Pagination