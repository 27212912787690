import {shortDateTime} from '../../lib/time'

function ReportedApplication(props){
    const application = props.application;

    const applicationStatus = function(status){
        if(status == 1){
            return <span className='text-success'>완료</span>;
        }else if(status == -1){
            return <span className='text-muted'>취소</span>;
        }else if(status == 0){
            return <span className='text-primary'>진행중</span>;
        }else{
            return status;
        }
    }

    return (
        <div className="basic_box"> 
            <div className="titwrap d-flex justify-content-between" style={{"paddingTop":"0px"}}>
                <div>거래 내역</div>                
            </div>
            <ul className="info_list">
            <li>
                <p className="tleft">대상</p>
                <p className="tright">{application.to_id}</p>
            </li>
            <li>
                <p className="tleft">{application.action == 'buy' ? <span className='txt_blue'>구매</span>:<span className='txt_red'>판매</span>}</p>
                <p className="tright">{applicationStatus(application.status)}</p>
            </li>
            <li>
                <p className="tleft">수량</p>
                <p className="tright">{application.amount}<small>({application.token_id})</small></p>
            </li>
            <li>
                <p className="tleft">시간</p>
                <p className="tright">{shortDateTime(application.createdAt)}</p>
            </li>
            {application.txHash &&
                <li>
                    <p className="tleft">txHash</p>
                    <p className="tright" style={{"wordBreak":"break-all"}}>{application.txHash}</p>
                </li>
            }
            <li>                
                {application.content}
            </li>
        </ul>     
        </div>
    )
}

export default ReportedApplication