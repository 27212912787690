import {tradingTableAmount,tableTradingStatus,actionTitle} from '../../lib/misc'
import {shortDateTime} from '../../lib/time'
import {throttle} from 'lodash'
import {useRef} from 'react'
import ReactMarkdown from 'react-markdown'

function CodeRequestInfo(props){
    const tradingitem = props.tradingitem;

    const reload = useRef(throttle(()=>{
        props.reloadTradingTableItem();
    },1000)).current
 
    return (
        <>
        <div className="titwrap d-flex justify-content-between">
                <div>{actionTitle(tradingitem.action)} 거래 상세 <span className="i_refresh02" onClick={reload}>새로고침</span></div>
            </div>
            <ul className="info_list">
            <li>                
                {tradingitem.title}
            </li>
            <li>
            <p className="tleft">아이디</p>
            <p className="tright">{tradingitem.user_id || '로딩중'}</p>
            </li>
            <li>
            <p className="tleft">토큰</p>
            <p className="tright">{tradingitem.token_name}({tradingitem.token_id})</p>
            </li>
            <li>
            <p className="tleft">거래수량</p>
            <p className="tright">{tradingTableAmount(tradingitem.amount)}</p>
            </li>
            <li>
            <p className="tleft">가격</p>
            <p className="tright">{tradingitem.maxPrice} - {tradingitem.minPrice}</p>
            </li>
            <li>
            <p className="tleft">등록일</p>
            <p className="tright">{shortDateTime(tradingitem.createdAt)}</p>
            </li>
            <li>
            <p className="tleft">상태</p>
            <p className="tright">{tableTradingStatus(tradingitem.status)}</p>
            </li>
            <li>
                {tradingitem.enableMarkDown == 1  ? <ReactMarkdown components={{em: ({node, ...props}) => <i style={{color: 'red'}} {...props} />}}>{tradingitem.content}</ReactMarkdown>:<pre>{tradingitem.content}</pre>}            
            </li>
        </ul>                
        
        </>
    )
}

export default CodeRequestInfo