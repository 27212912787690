import { useState,useContext, useEffect } from "react";
import AlertMessage from "../../lib/alertMessage";
import {numberFormat} from '../../lib/misc'
import {PreloaderContext} from "../preloaderContext"


function CodeInput(props){
    const [content,setContent] = useState('');
    const [isContentReadOnly,setContentReadOnly] = useState(false);
    const [amount,setAmount] = useState(0);
    const [isAmountReadOnly,setAmountReadOnly] = useState(false);
    const [txHash,setTxHash] = useState('');
    const {setLoading} = useContext(PreloaderContext);
    const [diabledTransfer,setDisabledTransfer] = useState(false);
    const tradingitem = props.tradingitem;
    const hitbitApi = props.hitbitApi;
    const code = props.code;
    let throttle = false;

    useEffect(()=>{
        
        if(tradingitem.requestCodeInformation){
            
            if(tradingitem.requestCodeInformation.amount > 0){
                setAmount(Number(tradingitem.requestCodeInformation.amount));
                setAmountReadOnly(true);
            }
    
            if(tradingitem.requestCodeInformation.content){
                setContent(tradingitem.requestCodeInformation.content);
                setContentReadOnly(true);
            }
        }
    },[tradingitem])

    const confirm = function(){
        AlertMessage("상대방에게 메세지를 전송하고 완료 합니다.","info",(value)=>{
            if(value){
                ///throttle
                if(!throttle)
                {
                    setLoading(true);
                    setDisabledTransfer(true);
                    throttle = true;                    
                    hitbitApi.newApplication({code,tid:tradingitem.id,to_id:tradingitem.user_id,amount,content,txHash}).then(res=>{                    
                        if(res.statusCode === 200){
                            props.reloadRequest(()=>{
                                setDisabledTransfer(false);
                            });                            
                        }else{
                            setDisabledTransfer(false);
                            AlertMessage(res.message);
                        }                    
                    }).finally(()=>{
                        setLoading(false);
                    })
                    
                    setTimeout(()=>{
                        throttle = false;                    
                    },3000);
                }
            }
        },{            
            confirmButtonText:'완료',
            cancelButtonText:'취소',
            showConfirmButton:true,
            showCancelButton:true,
        })
    }    

    const addAmount = function(nm){
        if(!isAmountReadOnly){
            if(nm == 0){
                setAmount(0)
            }else{
                setAmount(amount+nm)
            }
        }
    }

    const transfer = function(){
        AlertMessage(tradingitem.user_id+'에게 아래의 수량으로 전송합니다','',(result,value)=>{
            if(result){
                setLoading(true);
                let requestAmount = value.value;
                hitbitApi.transfer({target:tradingitem.user_id,token_id:tradingitem.token_id,amount:requestAmount}).then(res=>{
                    
                    if(res.statusCode === 200){
                        setAmount(requestAmount);
                        setTxHash(res.transaction.txHash);
                        AlertMessage('전송에 성공하였습니다','success');
                    }else{
                        AlertMessage(res.message);
                    }
                }).finally(()=>{
                    setLoading(false);
                })
            }
        },{            
            input: 'text',
            inputValue:amount,            
            inputAttributes: {
                autocapitalize: 'off',                                                
            },
            confirmButtonText:'전송',
            cancelButtonText:'취소',
            showConfirmButton:true,
            showCancelButton:true,
        });
    }

    if(tradingitem.action === 'sell'){

    return (
        <div className="basic_box codeinput">                    
            <div className="alert alert-warning" role="warning">
                <small>상대방과 충분한 대화 후 거래를 완료해 주세요. 거래에 발생한 문제에 대해서 책임지지 않습니다.</small>
            </div>
            <small className="c_tit">* 요청할 수량을 남겨주세요.</small>
            <input type="number" className="form-control" value={amount} onChange={(e)=>{setAmount(e.target.value)}} placeholder="ex) 0" readOnly={isAmountReadOnly}/>
            {amount >0  ? <p className="text-danger">{numberFormat(amount)}</p> : null}
            <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2">
                    <button className="btn btn-dark btn-block" onClick={(e)=>{    addAmount(10000);}}>1만</button>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2">
                    <button className="btn btn-dark btn-block" onClick={(e)=>{    addAmount(30000);}}>3만</button>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2">
                    <button className="btn btn-warning btn-block" onClick={(e)=>{    addAmount(50000);}}>5만</button>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2">
                    <button className="btn btn-warning btn-block" onClick={(e)=>{    addAmount(100000);}}>10만</button>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2">
                    <button className="btn btn-primary btn-block" onClick={(e)=>{    addAmount(300000);}}>30만</button>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2">
                    <button className="btn btn-primary btn-block" onClick={(e)=>{    addAmount(500000);}}>50만</button>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2">
                    <button className="btn btn-secondary btn-block" onClick={(e)=>{    addAmount(1000000);}}>100만</button>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2">
                    <button className="btn btn-danger btn-block" onClick={(e)=>{    addAmount(0);}}>정정</button>
                </div>
            </div>
            <small className="c_tit">* 입금자명을 꼭 기재해 주세요.</small>
            <textarea type="text" className="form-control" rows={4} onChange={(e)=>{setContent(e.target.value)}} value={content}  readOnly={isContentReadOnly} placeholder="ex) 입금자명."/>
            <button type='button' className="btn btn-primary btn-block mt-2" disabled={diabledTransfer}  onClick={confirm}> <span className="icon"></span>거래 완료</button>
        </div>
    )
    }else if(tradingitem.action === 'buy'){
        return (
            <>
            <div className="basic_box codeinput">                    
                <div className="alert alert-warning" role="warning">
                    <small>상대방과 충분한 대화 후 수량 전송 후 거래를 완료해 주세요. </small>
                </div>                
                <small className="c_tit">* 코인을 전송해 주세요.</small>
                <input type="number" className="form-control" onChange={(e)=>{setAmount(e.target.value)}} value={amount} readOnly/>
                <input type="text" className="form-control" value={txHash} onChange={(e)=>{setTxHash(e.target.value)}} placeholder="txHash"/>
                <button type='button' className="btn btn-info btn-block mt-2" onClick={transfer}> <span className="icon"></span>코인 전송</button>
                <small className="c_tit">* 상대방에게 확인할 수 있는 글을 남겨주세요.</small>
                <textarea type="text" className="form-control" rows={4} onChange={(e)=>{setContent(e.target.value)}} placeholder="ex) 코인을 전송하였습니다."/>
                <button type='button' className="btn btn-primary btn-block mt-2" onClick={confirm}> <span className="icon"></span>거래 완료</button>
            </div>
            </>
        )
    }else{
        return (
            <div className="basic_box codeinput">                    
                <div className="alert alert-danger" role="danger">
                    거래 상세 내역에 오류가 있습니다. 다시 확인해 주세요.
                </div>
            </div>
        )
    }
}

export default CodeInput
