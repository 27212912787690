
import React,{useContext} from 'react'
import { PreloaderContext } from './preloaderContext';

// 후에 fade out을 자연스럽게 달자..

function PreLoader(){
    const {isLoading} = useContext(PreloaderContext);
            
    return (
        isLoading && <div id="preloader" ></div>
    )    
}

export default PreLoader;