import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function AlertMessage(message,icon='error',cb=null,options={}){
    

    MySwal.fire({                    
        text:message,
        allowOutsideClick:false,
        icon,
        ...options
      }).then(value=>{          
          if(cb){
              cb(value.isConfirmed,value);
          }
      })
}        

export default AlertMessage