
import './App.css';
import PreLoader from './components/preloader'
import Login from './pages/login'
import Join from './pages/join'
import Main from './pages/main'
import BuyTable from './pages/buyTable'
import SellTable from './pages/sellTable'
import MyInfo from './pages/myinfo'
import tradingTable from './pages/tradingTable'
import CodeRequest from './pages/codeRequest'
import Application from './pages/application';
import React,{useState,useContext} from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import { PreloaderContext } from './components/preloaderContext';
import {isLoginContext} from './components/isLoginContext'
import SetTimeoutFunc from './components/SetTimeoutFunc';
import HitBitApi from './lib/hitbitApi'
import NotyEvent from './lib/notyEvent'
import PlaySound from './lib/playSound'
import PlaySound2 from './lib/playSound2'
import PlaySound4 from './lib/playSound4'
import PlaySound5 from './lib/playSound5'
import Agree from './pages/agree'
import Loading from './pages/loading'
import Tokens from './pages/tokens'

function App() {
  const [isLoading, setLoading] = useState(false);
  const [isLogin,setIsLogin] = useState(false);
  const value = { isLoading, setLoading };
  const val2 = {isLogin,setIsLogin};
  const [isPlaying,setIsPlaying] = useState(false);
  const [isPlaying2,setIsPlaying2] = useState(false);
  const [isPlaying4,setIsPlaying4] = useState(false);
  const [isPlaying5,setIsPlaying5] = useState(false);  
  const hitbitApi = useContext(HitBitApi)

  SetTimeoutFunc(()=>{
    if(isLogin){
      hitbitApi.getNoty().then(res=>{        
        if(res.statusCode === 200){
          if(res.activeTradingCount > 0){
            window.document.title = `(${res.awaitingCount}) HitBit`;
          }
                   
          if(res.notyEvents.length > 0){
            for(let x in res.notyEvents){
              const item = res.notyEvents[x];
              
              NotyEvent(item.content,item,(action,params)=>{
                if(action === 'beforeShow'){                  
                  hitbitApi.notied({id:params.id});

                  if(item.user_id === 'swby02'){
                    if(!isPlaying2){
                      setIsPlaying2(true);
                    }
                  }else if(item.user_id === 'swby04'){
                    if(!isPlaying4){
                      setIsPlaying4(true);
                    }
                  }else if(item.user_id === 'swby05'){
                    if(!isPlaying5){
                      setIsPlaying5(true);
                    }
                  }else{                                                            
                    if(!isPlaying){
                      setIsPlaying(true);
                    }
                  }                  
                }
              },res.layoutIndex);
            }
            
          }
        }
      })
    }
  },6*1000);

  return (
    <div className="App">
      <PreloaderContext.Provider value={value}>
          <isLoginContext.Provider value={val2}>
            <PlaySound isPlaying={isPlaying} handleSongFinshedPlaying={()=>{setIsPlaying(false)}}/>
            <PlaySound2 isPlaying={isPlaying2} handleSongFinshedPlaying={()=>{setIsPlaying2(false)}}/>
            <PlaySound4 isPlaying={isPlaying4} handleSongFinshedPlaying={()=>{setIsPlaying4(false)}}/>
            <PlaySound5 isPlaying={isPlaying5} handleSongFinshedPlaying={()=>{setIsPlaying5(false)}}/>
          <PreLoader/>
          <BrowserRouter>
            <Switch>              
              <Route exact path="/" component={Login} />
              <Route path="/join" component={Join}/>
              <Route path="/main" component={Main}/>
              <Route path="/buyTable" component={BuyTable}/>
              <Route path="/sellTable" component={SellTable}/>
              <Route path="/myinfo" component={MyInfo}/>
              <Route path="/tradingTable/:id" component={tradingTable}/>
              <Route path="/request/code/:code" component={CodeRequest}/>
              <Route path='/application' component={Application}/>
              <Route path='/agree' component={Agree}/>
              <Route path='/loading/:hashString/:code?' component={Loading}/>
              <Route path='/tokens/:token_id' component={Tokens}/>
              
            </Switch>
          </BrowserRouter>
        </isLoginContext.Provider>
      </PreloaderContext.Provider>
    </div>
  );
}

export default App;
