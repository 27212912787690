
const dayjs = require('dayjs')

module.exports =  {
    date(time=null){
        let retTime = null;
        if(time){
            retTime = new Date(time);
        }else{
            retTime = new Date();
        }

        return retTime;
    },

    onlyDate(original){
        return dayjs(original).format('YYYY-MM-DD');                
    },

    shortDateTime(original){
        return dayjs(original).format('YYYY-MM-DD HH:mm');                
    },

    fullDateTime(original){
        return dayjs(original).format('YYYY-MM-DD HH:mm:ss');                
    }
}