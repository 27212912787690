const numeral = require('numeral')
const NP = require('number-precision')
function _numberFormat(number){
    return numeral(number).format('0,0[.]0[0]');
}
module.exports={

    numberFormat(number){
        return _numberFormat(number);
    },
    tradingTableAmount(amount){
        if(amount == 0){
            return '무제한';
        }        
        return _numberFormat(amount);
    },
    actionTitle(action){
        if(action === 'buy'){
            return '구매';
        }else if(action === 'sell'){
            return '판매';
        }else{
            return action;
        }
    },
    tableTradingStatus(status){
        // 1 :active 0 : closed, 2 paused
        if(status == 1){
            return "거래 가능";
        } else if (status == 0){
            return "거래 종료"
        } else if (status == 2){
            return "휴식중"
        } 

        return "로딩중.";
    },
    parseCode(code){
        if(!code) {
            return null
        }

        let found = code.match(/^([A-Z-a-z]+)([01])(\w+)/);
        
        if(found.length > 3){
            return {
                token_id : found[1],
                flag : found[2] ,
                shortCode : found[3]
            }
        }else{
            return null
        }
    }
}